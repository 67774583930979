import dayjs from "dayjs";
const relativeTime = require("dayjs/plugin/relativeTime");
const timezone = require("dayjs/plugin/timezone");
const utc = require("dayjs/plugin/utc");
dayjs.extend(relativeTime);
// dayjs.extend(utc);
// dayjs.extend(timezone);
export const dateNow = () => {
  return dayjs().format("YYYY-MM-DD");
};
export const formatDateString = (value) => {
  return dayjs(value).format("YYYY-MM-DD");
};
export const formatDateAndTime = (value) => {
  return dayjs(value).format("MMM DD, YYYY HH:mm");
};
export const formatMonthYear = (value) => {
  return dayjs(value).format("MMM, YYYY");
};
export const formatFullMonthYear = (value) => {
  return dayjs(value).format("MMMM, YYYY");
};
export const formatFullDate = (value) => {
  return dayjs(value).format("MMMM DD, YYYY");
};
export const dateFromNow = (value) => {
  return dayjs(value).fromNow(true);
};
export const formatMonthShort = (value) => {
  return dayjs(value).format("MMM DD, YYYY");
};
export const formatDay = (value) => {
  return dayjs(value).format("ddd");
};
export const formatDateOfMonth = (value) => {
  return dayjs(value).format("DD");
};
export const formatMonth = (value) => {
  return dayjs(value).format("MMM");
};
export const formatYear = (value) => {
  return dayjs(value).format("YYYY");
};
export const formatTime = (value) => {
  return dayjs(value).format("HH:mm");
};
export const dateAfterWeek = () => {
  return dayjs().add(1, "week").format("YYYY-MM-DD");
};

export const isDateAfter = (value) => {
  const nowDayjs = dayjs();
  return dayjs(value).isAfter(nowDayjs.toISOString());
};
