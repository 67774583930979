import React from "react";

import { Box, Breadcrumbs, Typography } from "@mui/material/";
import { NavigateNext } from "@mui/icons-material";
import CustomerLists from "../components/table/CustomerLists";

const Customers = () => {
  return (
    <Box>
      <Box display={"flex"} flexDirection={"column"} gap={1} sx={{ p: 4 }}>
        <Typography variant="h5" fontWeight={500}>
          Customers Lists
        </Typography>
        <Breadcrumbs
          separator={<NavigateNext fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Typography key={1}>Weddlist</Typography>
          <Typography key={2} color={"primary"}>
            Customers
          </Typography>
        </Breadcrumbs>
      </Box>
      <CustomerLists />
    </Box>
  );
};

export default Customers;
