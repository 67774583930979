import { Add } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  IconButton,
  MenuItem,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useRef, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow, { tableRowClasses } from "@mui/material/TableRow";
import {
  deleteCoupon,
  getAllCoupons,
  getCouponLists,
} from "../../services/coupon";
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import { db } from "../../firebase-config";
import {
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import useIntersectionObserver from "../../utils/useIntersectionObserver";
import { formatToCapitalize } from "../../utils/caseFormat";
import {
  formatDateAndTime,
  formatDateString,
  formatMonthShort,
  formatTime,
  isDateAfter,
} from "../../utils/dateFormat";
import { useNavigate } from "react-router-dom";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";
import DeleteDialog from "../dialog/DeleteDialog";
import { _renderCurrency } from "../../utils/currencyFormat";
import Active from "../Active";
import NonActive from "../NonActive";
import { UserAuth } from "../../context/AuthContext";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.abu.main,
    color: theme.palette.common.black,
    textTransform: "uppercase",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontWeight: 600,
  },
}));

export default function CustomerLists() {
  const navigate = useNavigate();
  const { user } = UserAuth();
  const [sort, setSort] = useState("");
  const [search, setSearch] = useState("");

  const [openConfirm, setOpenConfirm] = useState(false);
  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };
  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const [selectedData, setSelectedData] = useState("");

  let customersQuery;

  customersQuery = query(
    collection(db, "users"),
    where("email", "!=", user.email),
    // orderBy("createdAt", "desc"),
    limit(10)
  );

  async function getCustomers(page) {
    return await getDocs(page);
  }

  const {
    error,
    data,
    isFetchingNextPage,
    isSuccess,
    isLoading,
    isError,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery({
    queryKey: ["customers"],
    queryFn: ({ pageParam = customersQuery }) => getCustomers(pageParam),
    getNextPageParam: (pageSnapshot, allPages) => {
      if (pageSnapshot.empty) return undefined;
      const lastDocument = pageSnapshot.docs[pageSnapshot.docs.length - 1];
      return query(customersQuery, startAfter(lastDocument));
    },
  });

  const loadMoreRef = useRef();

  useIntersectionObserver({
    target: loadMoreRef,
    onIntersect: fetchNextPage,
    enabled: hasNextPage,
  });

  const queryClient = useQueryClient();

  const mutationDelete = useMutation({
    mutationFn: deleteCoupon,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["customers"],
      });
    },
  });

  function handleDelete() {
    mutationDelete.mutate(selectedData);
    handleCloseConfirm();
  }

  return (
    <Box flexGrow={1}>
      <Box
        display={"flex"}
        flexDirection={"row"}
        flexGrow={1}
        justifyContent={"space-between"}
        gap={2}
        sx={{ px: 4 }}
      >
        <Box width={"50%"}>
          <TextField
            fullWidth
            margin="dense"
            label="Search customer"
            color="primary"
            InputProps={{ sx: { borderRadius: 3, bgcolor: "white" } }}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </Box>
        <Box
          width={"30%"}
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"end"}
          alignItems={"center"}
          gap={2}
        >
          <TextField
            size="medium"
            margin="dense"
            select
            label="Sort by"
            id="type"
            name="type"
            value={sort}
            onChange={(e) => setSort(e.target.value)}
            InputProps={{
              sx: { borderRadius: 3, bgcolor: "white", width: 150 },
            }}
          >
            <MenuItem value="date">Date</MenuItem>
          </TextField>
        </Box>
      </Box>

      <TableContainer
        sx={{
          maxHeight: "57dvh",
          mt: 2,
          // "&::-webkit-scrollbar": {
          //   width: 0,
          // },
        }}
      >
        <Table
          stickyHeader
          aria-label="sticky table"
          sx={{
            "& .MuiTableCell-sizeMedium": {
              padding: "12px 34px",
            },
          }}
        >
          <TableHead
            sx={{
              "&:.MuiTableHead-root": {
                p: 4,
              },
            }}
          >
            <TableRow>
              <StyledTableCell>UID</StyledTableCell>
              <StyledTableCell align="center">Photo</StyledTableCell>
              <StyledTableCell style={{ minWidth: 250 }}>Name</StyledTableCell>
              <StyledTableCell style={{ minWidth: 170 }}>Email</StyledTableCell>
              <StyledTableCell align="right" style={{ minWidth: 200 }}>
                Created At
              </StyledTableCell>
              <StyledTableCell align="right" style={{ minWidth: 200 }}>
                Last Login At
              </StyledTableCell>
              <StyledTableCell align="right" style={{ minWidth: 170 }}>
                Subscription
              </StyledTableCell>
              <StyledTableCell align="right" style={{ minWidth: 200 }}>
                Expire
              </StyledTableCell>
              <StyledTableCell align="right" style={{ minWidth: 170 }}>
                Action
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isSuccess &&
              data?.pages
                .flatMap((data) => data.docs)
                .map((customer, i) => {
                  const {
                    createdAt,
                    displayName,
                    email,
                    lastLoginAt,
                    phoneNumber,
                    photoURL,
                    plan,
                    valid_from,
                    valid_until,
                  } = customer.data();
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={customer.id}
                    >
                      <TableCell>{customer.id}</TableCell>
                      <TableCell align="center">
                        <Avatar alt={displayName} src={photoURL} />
                      </TableCell>
                      <TableCell
                        style={{ textTransform: "uppercase", fontWeight: 600 }}
                      >
                        {displayName}
                      </TableCell>
                      <TableCell>{email}</TableCell>
                      <TableCell align="right">
                        {formatDateAndTime(Number(createdAt))}
                      </TableCell>
                      <TableCell align="right">
                        {formatDateAndTime(Number(lastLoginAt))}
                      </TableCell>
                      <TableCell align="right">
                        <Box display={"flex"} justifyContent={"end"}>
                          {valid_until ? (
                            isDateAfter(valid_until.toDate()) === true ? (
                              <Active />
                            ) : (
                              <NonActive />
                            )
                          ) : (
                            <NonActive />
                          )}
                        </Box>
                      </TableCell>
                      <TableCell align="right">
                        {valid_until && formatDateAndTime(valid_until.toDate())}
                        {/* {`${formatMonthShort(
                          valid_until.toDate()
                        )} ${formatTime(valid_until.toDate())}`} */}
                      </TableCell>
                      <TableCell align="right">
                        <Box>
                          <IconButton
                            sx={{
                              color: "primary.main",
                              ":hover": {
                                backgroundColor: "primary.main",
                                color: "white",
                              },
                            }}
                          >
                            <LaunchOutlinedIcon />
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              setSelectedData(customer.id);
                              handleOpenConfirm();
                            }}
                            sx={{
                              color: "primary.main",
                              ":hover": {
                                backgroundColor: "primary.main",
                                color: "white",
                              },
                            }}
                          >
                            <DeleteOutlineOutlinedIcon />
                          </IconButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
            {hasNextPage && (
              <TableRow hover role="checkbox" tabIndex={-1} ref={loadMoreRef}>
                <TableCell>
                  <Skeleton animation="wave" />
                </TableCell>
                <TableCell align="right">
                  <Skeleton animation="wave" />
                </TableCell>
                <TableCell align="right">
                  <Skeleton animation="wave" />
                </TableCell>
                <TableCell align="right">
                  <Skeleton animation="wave" />
                </TableCell>
                <TableCell align="right">
                  <Skeleton animation="wave" />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <DeleteDialog
        title="Delete Coupon"
        value={selectedData}
        openDel={openConfirm}
        onCloseDel={handleCloseConfirm}
        handleDelete={handleDelete}
      />
    </Box>
  );
}
