import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  TextField,
  Rating,
  MenuItem,
  IconButton,
  InputAdornment,
  Snackbar,
  Typography,
} from "@mui/material/";
import LoadingButton from "@mui/lab/LoadingButton";

import {
  CalendarMonth,
  AccessAlarm,
  AddCircleOutline,
} from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";

import { db } from "../../firebase-config";
import {
  addDoc,
  query,
  collection,
  getDocs,
  where,
  Timestamp,
} from "firebase/firestore";
import { useFormik } from "formik";
import { object, string, date, boolean, number } from "yup";
import { UserVendors } from "../../context/VendorContext";
import { UserAuth } from "../../context/AuthContext";
import { formatSlug } from "../../utils/slugFormat";
import { indigo } from "@mui/material/colors";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { addCoupon } from "../../services/coupon";
import { addTerms } from "../../services/terms";

const FormNewTerms = ({ onCancel }) => {
  const queryClient = useQueryClient();
  const [alertStatus, setAlertStatus] = useState(false);
  const navigate = useNavigate();
  const { user } = UserAuth();

  const handleCloseAlertStatus = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertStatus(false);
  };

  useEffect(() => {
    const getCountTerms = async () => {
      const querySnapshot = await getDocs(collection(db, "TnC"));
      const index = querySnapshot.size + 1;
      setFieldValue("orderID", index);
      setFieldValue("userID", user.uid);
    };
    getCountTerms();
  }, []);

  const {
    handleSubmit,
    handleChange,
    errors,
    touched,
    dirty,
    isValid,
    handleBlur,
    isSubmitting,
    setSubmitting,
    resetForm,
    values,
    setValues,
    setFieldValue,
  } = useFormik({
    initialValues: {
      content: "",
      orderID: "",
      userID: "",
    },
    onSubmit: (val) => {
      addMutation.mutate(val);
    },
    validationSchema: object().shape({
      content: string().required("Type is required"),
    }),
  });

  const addMutation = useMutation({
    mutationFn: addTerms,
    onSuccess: (data) => {
      // console.log("Success", data);
      queryClient.invalidateQueries({ queryKey: ["terms"] });
      navigate("/terms", { replace: true });
    },
  });

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseAlertStatus}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <Box>
      <Snackbar
        open={alertStatus}
        var
        autoHideDuration={3000}
        onClose={handleCloseAlertStatus}
        message="Client has been created successfully"
        action={action}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{ paddingX: 1 }}
        ContentProps={{
          sx: {
            background: indigo["600"],
          },
        }}
      />
      <Box display={"flex"} flexDirection={"column"} py={2} gap={1}>
        <Typography variant="h6" fontWeight={500} sx={{ pb: 1 }}>
          Term and Conditions Form #{values.orderID}
        </Typography>
        <Box width="100%" display="flex" gap={1}>
          <TextField
            fullWidth
            margin="dense"
            label="Term & Conditions"
            placeholder="Type terms and conditions here..."
            multiline
            rows={12}
            InputLabelProps={{ shrink: true }}
            id="content"
            name="content"
            color="primary"
            InputProps={{
              sx: { borderRadius: 3, bgcolor: "white" },
            }}
            value={values.content}
            onBlur={handleBlur}
            error={touched.content && Boolean(errors.content)}
            helperText={touched.content && errors.content}
            onChange={handleChange}
          />
        </Box>
      </Box>
      <Box
        width={"20%"}
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"end"}
        gap={2}
        paddingBottom={5}
      >
        <LoadingButton
          fullWidth
          sx={{ borderRadius: 3 }}
          size="large"
          onClick={() => handleSubmit()}
          loading={isSubmitting}
          loadingPosition="center"
          variant="contained"
        >
          Add New Terms
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default FormNewTerms;
