import {
  Timestamp,
  collection,
  deleteDoc,
  doc,
  getDocs,
  limit,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  startAt,
  where,
} from "firebase/firestore";
import { db } from "../firebase-config";
import { singleCode } from "../utils/generateCoupon";

export const addCoupon = async (body) => {
  await setDoc(doc(db, "coupons", singleCode()), {
    type: body.type,
    price: Number(body.price),
    isUsed: false,
    usedBy: "",
    createdAt: serverTimestamp(),
  });
  return "OK";
};
export const getAllCoupons = async () => {
  const snapshot = await getDocs(query(collection(db, "coupons")));
  return snapshot.size;
};

export async function deleteCoupon(idCoupon) {
  const docRef = doc(db, "coupons", idCoupon);
  return deleteDoc(docRef);
}

export const getCouponLists = async () => {
  // const { size } = await getDocs(query(collection(db, "coupons")));
  const querySnapshot = await getDocs(
    query(collection(db, "coupons"), orderBy("createdAt", "asc"), limit(2))
  );
  // let data = [];
  // querySnapshot.forEach((doc) => {
  //   const d = {
  //     id: doc.id,
  //     ...doc.data(),
  //   };
  //   data.push(d);
  // });
  //   const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
  // console.log("data", size);
  return querySnapshot;
};
