import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";

function ErrorPage(props) {
  return (
    <div>
      <Grid container spacing={4} sx={{ p: 4 }}>
        <Grid item>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={4}
          >
            <h1>Oops! ERROR 404 PAGE NOT FOUND</h1>
            {/* <p>Sorry, an unexpected error has occurred.</p> */}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default ErrorPage;
