import { useContext, createContext, useEffect, useState } from "react";
import {
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  updateProfile,
  verifyBeforeUpdateEmail,
  updatePassword,
} from "firebase/auth";
import { auth, db } from "../firebase-config";
import LoadingSection from "../components/LoadingSection";
import { doc, getDoc } from "firebase/firestore";

const AuthContext = createContext();

export function AuthContextProvider({ children }) {
  const [user, setUser] = useState(null);
  const [pending, setPending] = useState(true);

  function signIn(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }

  function logOut() {
    return signOut(auth);
  }

  function updateName(name) {
    return updateProfile(auth.currentUser, { displayName: name });
  }
  function updateEmailUser(email) {
    return verifyBeforeUpdateEmail(auth.currentUser, email);
  }
  function updatePasswordUser(password) {
    return updatePassword(auth.currentUser, password);
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser !== null) {
        const docSnap = await getDoc(doc(db, "users", currentUser.uid));
        if (docSnap.data().role === "super-admin") {
          setUser(currentUser);
        } else {
          logOut();
          setUser(null);
        }
        setPending(false);
      }
      setUser(currentUser);
      setPending(false);
      // console.log(currentUser);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  if (pending) {
    return (
      <>
        <LoadingSection status={pending} />
      </>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        signIn,
        logOut,
        updateName,
        updateEmailUser,
        updatePasswordUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function UserAuth() {
  return useContext(AuthContext);
}
