import {
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";

export default function DeleteDialog({
  title,
  value,
  openDel,
  onCloseDel,
  handleDelete,
}) {
  return (
    <Dialog
      open={openDel}
      keepMounted
      PaperProps={{ sx: { borderRadius: 4, width: { xs: "100%", md: 450 } } }}
    >
      <DialogTitle sx={{ pb: 1 }}>{title}</DialogTitle>
      <DialogContent sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        <Box
          sx={{
            border: "1px solid lightgrey",
            borderRadius: 3,
            px: 2,
            py: 1.5,
            bgcolor: "ninth.light",
          }}
        >
          <Typography
            variant="body2"
            fontWeight={600}
            color="abu"
            textAlign={"justify"}
          >
            Are you sure you want to delete{" "}
            <span style={{ color: "red" }}>{value}</span> ?
          </Typography>
        </Box>
        <Box display="flex" justifyContent="end" gap={1}>
          <Button
            onClick={onCloseDel}
            variant="outlined"
            color="secondary"
            sx={{ borderRadius: 2.5, textTransform: "capitalize" }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{
              borderRadius: 2.5,
              textTransform: "capitalize",
              bgcolor: "primary.dark",
              color: "white",
              fontWeight: 600,
            }}
            onClick={() => {
              try {
                handleDelete();
                onCloseDel();
              } catch (error) {
                console.log(error);
              }
            }}
          >
            Delete
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
