import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ForumIcon from "@mui/icons-material/Forum";
import PeopleIcon from "@mui/icons-material/People";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import ReceiptIcon from "@mui/icons-material/Receipt";
import AssessmentIcon from "@mui/icons-material/Assessment";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonIcon from "@mui/icons-material/Person";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import {
  KeyboardArrowUp,
  KeyboardArrowDown,
  RadioButtonChecked,
  RadioButtonUnchecked,
} from "@mui/icons-material/";
import {
  Avatar,
  Badge,
  Collapse,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Tooltip,
} from "@mui/material";
import { useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { grey, indigo } from "@mui/material/colors";
import { useEffect } from "react";
import { UserAuth } from "../context/AuthContext";

function notificationsLabel(count) {
  if (count === 0) {
    return "no notifications";
  }
  if (count > 99) {
    return "more than 99 notifications";
  }
  return `${count} notifications`;
}

const drawerWidth = "20vw";

const initialOpen = { coupons: false, terms: false };

export default function Header({ children }) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(initialOpen);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [anchorNotif, setAnchorNotif] = useState(null);
  const { logOut, user } = UserAuth();

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenNotif = (event) => {
    setAnchorNotif(event.currentTarget);
  };
  const handleCloseNotif = () => {
    setAnchorNotif(null);
  };

  async function handleSignOut() {
    try {
      await logOut();
      sessionStorage.setItem("isLogin", false);
    } catch (error) {
      console.log(error);
    }
  }

  const toggleCoupons = () => {
    // setOpen((prev) => ({
    //   coupons: !prev.coupons,
    // }));
    if (open.coupons === true) {
      setOpen((prev) => ({
        coupons: false,
      }));
    } else {
      setOpen((prev) => ({
        coupons: true,
      }));
    }

    navigate("/coupons");
  };

  // Function to toggle terms
  const toggleTerms = () => {
    // setOpen((prev) => ({
    //   terms: !prev.terms,
    // }));
    if (open.terms === true) {
      setOpen((prev) => ({
        terms: false,
      }));
    } else {
      setOpen((prev) => ({
        terms: true,
      }));
    }
    navigate("/terms");
  };

  return (
    <Box
      bgcolor={"abu.main"}
      sx={{ display: "flex", minHeight: "100dvh", width: "100dvw" }}
    >
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, bgcolor: "abu.main" }}
        elevation={0}
      >
        <Toolbar>
          <Box
            display={"flex"}
            flexDirection={"row"}
            flexGrow={1}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Box
              component="img"
              display="flex"
              width={200}
              //   height={"100%"}
              alt="NOTA Logo"
              src="/logo-h.png"
            />
            <Box display={"flex"} flexDirection={"row"} gap={2}>
              <Box sx={{ flexGrow: 0 }}>
                <Tooltip title="Dark Mode" arrow placement="bottom-start">
                  <IconButton
                    // onClick={handleOpenUserMenu}

                    sx={{ p: 0 }}
                  >
                    <DarkModeIcon />
                  </IconButton>
                </Tooltip>
              </Box>
              <Box sx={{ flexGrow: 0 }}>
                <Tooltip title="Notifications" arrow placement="bottom-start">
                  <IconButton
                    onClick={handleOpenNotif}
                    aria-label={notificationsLabel(4)}
                    sx={{ p: 0 }}
                  >
                    <Badge
                      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                      badgeContent={4}
                      color="primary"
                    >
                      <NotificationsNoneIcon />
                    </Badge>
                  </IconButton>
                </Tooltip>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorNotif}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorNotif)}
                  onClose={handleCloseNotif}
                  // PaperProps={{
                  //   style: {
                  //     maxWidth: 150,
                  //   },
                  // }}
                >
                  <MenuItem
                    onClick={() => {
                      handleCloseUserMenu();
                      // navigate("/profile/personal");
                    }}
                  >
                    <Typography textAlign="left">Lorem ipsum</Typography>
                  </MenuItem>
                </Menu>
              </Box>
              <Box sx={{ flexGrow: 0 }}>
                <Tooltip title="Open Menu" arrow placement="bottom-start">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar
                      alt={user.displayName}
                      src={user.photoURL}
                      sx={{ width: 32, height: 32 }}
                    />
                  </IconButton>
                </Tooltip>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                  PaperProps={{
                    style: {
                      width: 150,
                    },
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      handleCloseUserMenu();
                      // navigate("/profile/personal");
                    }}
                  >
                    <Typography textAlign="left">Profile</Typography>
                  </MenuItem>

                  <MenuItem onClick={handleSignOut}>
                    <Typography textAlign="left">Logout</Typography>
                  </MenuItem>
                </Menu>
              </Box>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box",
            bgcolor: "abu.main",
            borderRight: 0,
            paddingRight: 3,
          },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: "auto" }}>
          <Divider textAlign="left">
            <Typography variant="caption" color={"grey"} fontWeight={300}>
              MAIN
            </Typography>
          </Divider>
          <List>
            <ListItem id="sidebarMenu" disablePadding>
              <ListItemButton component={NavLink} to={"/home"}>
                <DashboardIcon />
                <ListItemText className="textMenu" primary="Dashboard" />
              </ListItemButton>
            </ListItem>
            <ListItem id="sidebarMenu" disablePadding>
              <ListItemButton disabled component={NavLink} to={"/chat"}>
                <ForumIcon />
                <ListItemText primary="Chat" />
              </ListItemButton>
            </ListItem>
          </List>
          <Divider textAlign="left">
            <Typography variant="caption" color={"grey"} fontWeight={300}>
              APPS
            </Typography>
          </Divider>
          <List>
            <ListItem id="sidebarMenu" disablePadding>
              <ListItemButton component={NavLink} to={"/customers"}>
                <PeopleIcon />

                <ListItemText primary="Customers" />
              </ListItemButton>
            </ListItem>
            <ListItem id="sidebarMenu" disablePadding>
              <ListItemButton disabled component={NavLink} to={"/vendors"}>
                <Diversity3Icon />

                <ListItemText primary="Vendors" />
              </ListItemButton>
            </ListItem>
            <ListItem id="sidebarMenu" disablePadding>
              <ListItemButton
                component="a"
                onClick={toggleCoupons}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  flexGrow: 1,
                  justifyContent: "space-between",
                  pr: 1,
                }}
              >
                <ReceiptIcon />
                <ListItemText primary="Coupons" />
                {open.coupons ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
              </ListItemButton>
            </ListItem>
            <Collapse in={open.coupons} timeout="auto" unmountOnExit>
              <ListItem id="sidebarSubMenu" disablePadding>
                <ListItemButton
                  // sx={{ pl: 9 }}
                  component={NavLink}
                  to={"/coupons"}
                >
                  <RadioButtonChecked sx={{ fontSize: "10px" }} />
                  <ListItemText primary="Coupons Lists" sx={{ px: 1 }} />
                </ListItemButton>
              </ListItem>
              <ListItem id="sidebarSubMenu" disablePadding>
                <ListItemButton
                  // sx={{ pl: 9 }}
                  component={NavLink}
                  to={"/new-coupon"}
                >
                  <RadioButtonChecked sx={{ fontSize: "10px" }} />
                  <ListItemText primary="Add New Coupon" sx={{ px: 1 }} />
                </ListItemButton>
              </ListItem>
            </Collapse>
            <ListItem id="sidebarMenu" disablePadding>
              <ListItemButton
                component="a"
                onClick={toggleTerms}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  flexGrow: 1,
                  justifyContent: "space-between",
                  pr: 1,
                }}
              >
                <ReceiptIcon />
                <ListItemText primary="Terms" />
                {open.terms ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
              </ListItemButton>
            </ListItem>
            <Collapse in={open.terms} timeout="auto" unmountOnExit>
              <ListItem id="sidebarSubMenu" disablePadding>
                <ListItemButton
                  // sx={{ pl: 9 }}
                  component={NavLink}
                  to={"/terms"}
                >
                  <RadioButtonChecked sx={{ fontSize: "10px" }} />
                  <ListItemText primary="Term & Conditions" sx={{ px: 1 }} />
                </ListItemButton>
              </ListItem>
              <ListItem id="sidebarSubMenu" disablePadding>
                <ListItemButton
                  // sx={{ pl: 9 }}
                  component={NavLink}
                  to={"/new-term"}
                >
                  <RadioButtonChecked sx={{ fontSize: "10px" }} />
                  <ListItemText primary="Add Terms" sx={{ px: 1 }} />
                </ListItemButton>
              </ListItem>
            </Collapse>
            <ListItem id="sidebarMenu" disablePadding>
              <ListItemButton disabled component={NavLink} to={"/summary"}>
                <AssessmentIcon />

                <ListItemText primary="Summary" />
              </ListItemButton>
            </ListItem>
            <ListItem id="sidebarMenu" disablePadding>
              <ListItemButton disabled component={NavLink} to="/settings">
                <SettingsOutlinedIcon />

                <ListItemText primary="Settings" />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          // width: "100dvw",

          // alignContent: "space-between",
          // flex: 1,
          pl: 1,
          py: 1,
          pr: 1,
        }}
      >
        <Toolbar />
        <Paper elevation={2} sx={{ bgcolor: "white", width: "77dvw" }}>
          {children}
        </Paper>
      </Box>
    </Box>
  );
}
