import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Grid,
  Typography,
  InputAdornment,
  TextField,
  Divider,
  Box,
  Card,
  CardContent,
  IconButton,
  Icon,
  Chip,
} from "@mui/material/";
import LoadingButton from "@mui/lab/LoadingButton";
import GoogleIcon from "@mui/icons-material/Google";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { NavLink } from "react-router-dom";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import LockIcon from "@mui/icons-material/Lock";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import LogoBrand from "../assets/logo/logo-brand.webp";
import GoogleColorIcon from "../assets/icons/google-icon.svg";
import { UserAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import AlertAuth from "../components/AlertAuth";
import LoginAnim from "../assets/anim/login.json";
import MaleAnim from "../assets/anim/male.json";
import lottie from "lottie-web";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase-config";

const Login = () => {
  const { signIn, googleSignIn, user } = UserAuth();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [admin, setAdmin] = useState("");

  const container = useRef(null);
  const container2 = useRef(null);

  useEffect(() => {
    if (user != null) {
      // console.log(user);
      navigate("/home");
    }
    const instance = lottie.loadAnimation({
      container: container.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: LoginAnim,
    });
    const animMale = lottie.loadAnimation({
      container: container2.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: MaleAnim,
    });
    return () => {
      instance.destroy();
      animMale.destroy();
    };
  }, [user]);

  const handleSignIn = async () => {
    try {
      setError("");
      setLoading(true);
      await signIn(email, password);
    } catch (err) {
      if (err.code === "auth/invalid-email") {
        setError("Email is invalid. Please input the correct email.");
      }
      if (err.code === "auth/invalid-login-credentials") {
        setError("Login failed. Please try again later.");
      }
      if (err.code === "auth/user-not-found") {
        setError("User is not found. Please input the correct email.");
      }
      if (err.code === "auth/wrong-password") {
        setError("Password is wrong. Please input the correct password.");
      }
      if (err.code === "auth/too-many-requests") {
        setError(
          "Access to this account has been temporarily disabled due to many failed login attempts. You can try again later."
        );
      }
    }
    setLoading(false);
  };

  const handleGoogleSignIn = async () => {
    try {
      await googleSignIn();
      sessionStorage.setItem("isLogin", true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div>
      <div className="bg-login">
        <Box
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
          sx={{ display: { xs: "none", md: "flex" } }}
        >
          <Card
            sx={{
              borderRadius: 4,
              display: "flex",
              width: 900,
              height: 600,
            }}
            elevation={3}
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent={"space-between"}
              p={4}
              sx={{
                width: "50%",
                height: "100%",
                backgroundColor: "primary.main",
              }}
            >
              <Box display="flex" justifyContent="start">
                <Typography
                  variant="h4"
                  fontWeight={800}
                  color="white"
                  textTransform={"uppercase"}
                >
                  LOGIN SUPER ADMIN
                </Typography>
              </Box>
              <Box display="flex" justifyContent="center" alignItems="center">
                <div className="container" ref={container}></div>
              </Box>
              <Box
                display="flex"
                justifyContent="start"
                flexDirection={"column"}
              >
                <Typography
                  variant="h4"
                  fontWeight={600}
                  color="white"
                  textTransform={"uppercase"}
                >
                  All In One
                </Typography>
                <Typography variant="h4" fontWeight={300} color="white">
                  Solution For
                </Typography>
                <Typography variant="h5" fontWeight={200} color="white">
                  Professional Wedding Services
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                width: "50%",
                flexDirection: "column",
                justifyContent: "center",
                bgcolor: "white",
                paddingX: 2,
              }}
            >
              <CardContent>
                <Grid container direction="column" justifyContent="center">
                  <Grid
                    item
                    paddingX={2}
                    paddingBottom={1}
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    xl={3}
                  >
                    <Box
                      component="img"
                      display="flex"
                      margin="auto"
                      width={200}
                      alt="NOTA Logo"
                      src={LogoBrand}
                    />
                  </Grid>
                  <Grid
                    item
                    paddingX={4}
                    paddingBottom={3}
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    xl={3}
                  >
                    <Typography variant="body1">
                      Please fill with admin account.
                    </Typography>
                  </Grid>
                  {error && (
                    <Grid item paddingX={3} xs={12} sm={6} md={4} lg={3} xl={3}>
                      <AlertAuth status="error" message={error} />
                    </Grid>
                  )}
                  <Grid item paddingX={3} xs={12} sm={6} md={4} lg={3} xl={3}>
                    <Box width="100%" display="flex" flexDirection="column">
                      <Box
                        width="100%"
                        flexDirection="row"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <TextField
                          id="input-with-icon-textfield"
                          label="Email"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <AlternateEmailIcon />
                              </InputAdornment>
                            ),
                          }}
                          variant="outlined"
                          type="email"
                          required={true}
                          fullWidth
                          placeholder="Type your email..."
                          onChange={(event) => {
                            setEmail(event.target.value);
                          }}
                        />
                      </Box>
                      <Box
                        marginTop={2}
                        marginBottom={1}
                        width="100%"
                        flexDirection="row"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <TextField
                          id="input-with-icon-textfield"
                          label="Password"
                          placeholder="Type your password..."
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <LockIcon />
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <VisibilityOff fontSize="small" />
                                  ) : (
                                    <Visibility fontSize="small" />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          variant="outlined"
                          type={showPassword ? "text" : "password"}
                          required={true}
                          onChange={(event) => {
                            setPassword(event.target.value);
                          }}
                          fullWidth
                        />
                      </Box>
                    </Box>

                    <LoadingButton
                      onClick={() => handleSignIn()}
                      loading={loading}
                      loadingPosition="center"
                      variant="contained"
                      sx={{
                        width: "100%",
                        height: 40,
                        textTransform: "none",
                        borderRadius: 3,
                        marginBottom: 1,
                        marginTop: 2,
                        color: "#ffffff",
                      }}
                    >
                      Sign in
                    </LoadingButton>
                  </Grid>
                </Grid>
              </CardContent>
            </Box>
          </Card>
        </Box>
      </div>

      <Grid
        container
        direction="column"
        justifyContent="center"
        sx={{ display: { xs: "flex", md: "none" } }}
      >
        <Grid
          item
          paddingX={2}
          paddingBottom={1}
          xs={12}
          sm={6}
          md={4}
          lg={3}
          xl={3}
        >
          <Box width="90%" margin="auto">
            <div className="container" ref={container2}></div>
          </Box>
        </Grid>
        <Grid
          item
          paddingX={4}
          paddingY={2}
          xs={12}
          sm={6}
          md={4}
          lg={3}
          xl={3}
        >
          <Typography variant="h4" fontWeight={600}>
            Login
          </Typography>
        </Grid>
        {error && (
          <Grid item paddingX={6} xs={12} sm={6} md={4} lg={3} xl={3}>
            <AlertAuth status="error" message={error} />
          </Grid>
        )}
        <Grid item paddingX={3} xs={12} sm={6} md={4} lg={3} xl={3}>
          <Box width="100%" display="flex" flexDirection="column">
            <Box
              width="100%"
              flexDirection="row"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <AlternateEmailIcon sx={{ width: "10%", marginRight: 1 }} />
              <TextField
                id="input-with-icon-textfield"
                placeholder="Email Address"
                sx={{ width: "90%" }}
                fullWidth
                variant="standard"
                type="email"
                required={true}
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
              />
            </Box>
            <Box
              marginTop={2}
              marginBottom={1}
              width="100%"
              flexDirection="row"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <LockIcon sx={{ width: "10%", marginRight: 1 }} />
              <TextField
                placeholder="Password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? (
                          <VisibilityOff fontSize="small" />
                        ) : (
                          <Visibility fontSize="small" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{ width: "90%" }}
                variant="standard"
                type={showPassword ? "text" : "password"}
                required={true}
                onChange={(event) => {
                  setPassword(event.target.value);
                }}
                fullWidth
              />
            </Box>
          </Box>
          <LoadingButton
            onClick={() => handleSignIn()}
            loading={loading}
            loadingPosition="center"
            variant="contained"
            sx={{
              width: "100%",
              height: 40,
              textTransform: "none",
              borderRadius: 8,
              marginBottom: 1,
              marginTop: 2,
              color: "#ffffff",
            }}
          >
            Sign in
          </LoadingButton>
        </Grid>
      </Grid>

      {/* </Box> */}
    </div>
  );
};

export default Login;
