import React, { useEffect, useState } from "react";
import { Collapse, Alert, IconButton, Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const AlertAuth = (props) => {
  const [alert, setAlert] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setAlert(false);
    }, 3500);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <Stack sx={{ width: "100%" }} spacing={2}>
      <Collapse in={alert}>
        <Alert
          severity={props.status}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setAlert(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 3, width: "100%" }}
        >
          {props.message}
        </Alert>
      </Collapse>
    </Stack>
  );
};

export default AlertAuth;
