import { Box, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import React from "react";

export default function NonActive() {
  return (
    <Box
      width={120}
      display={"flex"}
      flexDirection={"row"}
      justifyContent={"space-between"}
      bgcolor={grey["400"]}
      borderRadius={5}
      gap={1}
      p={1}
    >
      <Box width={20} height={20} borderRadius={"100%"} bgcolor={"white"} />
      <Box flexGrow={1}>
        <Typography
          variant="caption"
          fontWeight={600}
          color={"black"}
          textTransform={"uppercase"}
        >
          Non-Active
        </Typography>
      </Box>
    </Box>
  );
}
