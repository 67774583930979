import React, { useState, useEffect } from "react";

import { Box, Breadcrumbs, Typography } from "@mui/material/";
import { NavigateNext } from "@mui/icons-material";
import FormNewCoupon from "../../components/form/NewCoupon";
import FormNewTerms from "../../components/form/NewTerms";

const AddTerms = () => {
  return (
    <Box sx={{ p: 4 }}>
      <Box display={"flex"} flexDirection={"column"} gap={1} pb={4}>
        <Typography variant="h5" fontWeight={500}>
          Add New Terms
        </Typography>
        <Breadcrumbs
          separator={<NavigateNext fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Typography key={1}>Weddlist</Typography>
          <Typography key={2}>Terms</Typography>
          <Typography key={3} color={"primary"}>
            Add New Terms
          </Typography>
        </Breadcrumbs>
      </Box>
      <FormNewTerms />
    </Box>
  );
};

export default AddTerms;
