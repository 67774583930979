import { createTheme, responsiveFontSizes } from "@mui/material/styles";

import "@fontsource/outfit/100.css";
import "@fontsource/outfit/200.css";
import "@fontsource/outfit/300.css";
import "@fontsource/outfit/400.css";
import "@fontsource/outfit/500.css";
import "@fontsource/outfit/600.css";
import "@fontsource/outfit/700.css";
import "@fontsource/outfit/800.css";
import "@fontsource/outfit/900.css";
import { grey, indigo } from "@mui/material/colors";

const base = createTheme({
  typography: {
    fontFamily: '"Outfit", sans-serif',
  },
  palette: {
    primary: {
      main: indigo["600"],
      light: indigo["300"],
    },
    secondary: {
      main: "#1e0f5d",
    },
    third: {
      main: "#b0a7cf",
    },
    fourth: {
      main: "#4933a0",
    },
    fifth: {
      main: "#f8f8fb",
    },
    sixth: {
      main: "#7B2CBF",
      light: "#F0F0F0",
    },
    seventh: {
      main: "#5A189A",
    },
    eighth: {
      main: "#E0AAFF",
    },
    ninth: {
      main: "#5686FF",
      light: "#F2F6FE",
    },
    tenth: {
      main: "#018294",
    },
    putih: {
      main: "#ffffff",
    },
    abu: {
      main: "#F3F3F3",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      xxl: 1800,
    },
  },
});

const theme = responsiveFontSizes(base);

export default theme;
