import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  TextField,
  Rating,
  MenuItem,
  IconButton,
  InputAdornment,
  Snackbar,
  Typography,
} from "@mui/material/";
import LoadingButton from "@mui/lab/LoadingButton";

import {
  CalendarMonth,
  AccessAlarm,
  AddCircleOutline,
} from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";

import { db } from "../../firebase-config";
import {
  addDoc,
  query,
  collection,
  getDocs,
  where,
  Timestamp,
} from "firebase/firestore";
import { useFormik } from "formik";
import { object, string, date, boolean, number } from "yup";
import { UserVendors } from "../../context/VendorContext";
import { UserAuth } from "../../context/AuthContext";
import { formatSlug } from "../../utils/slugFormat";
import { indigo } from "@mui/material/colors";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { addCoupon } from "../../services/coupon";
import { singleCode } from "../../utils/generateCoupon";
import { NumericFormat } from "react-number-format";

const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      valueIsNumericString
      prefix="Rp. "
    />
  );
});

NumericFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const FormNewCoupon = ({ onCancel }) => {
  const queryClient = useQueryClient();
  const [alertStatus, setAlertStatus] = useState(false);
  const navigate = useNavigate();
  const { user } = UserAuth();

  const handleCloseAlertStatus = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertStatus(false);
  };

  const {
    handleSubmit,
    handleChange,
    errors,
    touched,
    dirty,
    isValid,
    handleBlur,
    isSubmitting,
    setSubmitting,
    resetForm,
    values,
    setValues,
    setFieldValue,
  } = useFormik({
    initialValues: {
      type: "",
      price: "",
    },
    onSubmit: (val) => {
      addMutation.mutate(val);
    },
    validationSchema: object().shape({
      type: string().required("Type is required"),
      price: number().required("Price is required"),
    }),
  });

  const addMutation = useMutation({
    mutationFn: addCoupon,
    onSuccess: (data) => {
      // console.log("Success", data);
      queryClient.invalidateQueries({ queryKey: ["coupons"] });
      navigate("/coupons", { replace: true });
    },
  });

  console.log("gg", values.price);

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseAlertStatus}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <Box>
      <Snackbar
        open={alertStatus}
        var
        autoHideDuration={3000}
        onClose={handleCloseAlertStatus}
        message="Client has been created successfully"
        action={action}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{ paddingX: 1 }}
        ContentProps={{
          sx: {
            background: indigo["600"],
          },
        }}
      />
      <Box display={"flex"} flexDirection={"column"} py={2} gap={1}>
        <Typography variant="h6" fontWeight={500} sx={{ pb: 1 }}>
          Coupon Form
        </Typography>
        <Box width="50%" display="flex" gap={1}>
          <TextField
            margin="dense"
            select
            label="Type"
            id="type"
            name="type"
            value={values.type}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.type && Boolean(errors.type)}
            helperText={touched.type && errors.type}
            fullWidth
            InputProps={{ sx: { borderRadius: 3, bgcolor: "white" } }}
          >
            <MenuItem value="1-month">1 Month</MenuItem>
            <MenuItem value="6-month">6 Month</MenuItem>
            <MenuItem value="1-year">1 Year</MenuItem>
          </TextField>
          <Box display={"flex"} width={"90%"}>
            <TextField
              fullWidth
              margin="dense"
              label="Amount Price"
              id="price"
              name="price"
              color="primary"
              // type="number"
              InputProps={{
                sx: { borderRadius: 3, bgcolor: "white" },
                // startAdornment: (
                //   <InputAdornment position="start">Rp.</InputAdornment>
                // ),
                inputComponent: NumericFormatCustom,
              }}
              value={values.price}
              onBlur={handleBlur}
              error={touched.price && Boolean(errors.price)}
              helperText={touched.price && errors.price}
              onChange={handleChange}
            />
          </Box>
          <Box
            mt={1}
            width={250}
            height={55}
            borderRadius={3}
            bgcolor={"primary.dark"}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Typography variant="caption" fontWeight={300} color={"white"}>
              Coupon
            </Typography>
            <Typography
              variant="body1"
              fontWeight={600}
              color={"white"}
              textTransform={"uppercase"}
            >
              {`${values.type.split("-")[0]} ${values.type.split("-")[1]}`}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        width={"20%"}
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"end"}
        gap={2}
        paddingBottom={5}
      >
        <LoadingButton
          fullWidth
          sx={{ borderRadius: 3 }}
          size="large"
          onClick={() => handleSubmit()}
          loading={isSubmitting}
          loadingPosition="center"
          variant="contained"
        >
          Add New Coupon
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default FormNewCoupon;
