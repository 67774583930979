import { Routes, Route } from "react-router-dom";
import React, { lazy, Suspense } from "react";
import Login from "../views/Login";
import ErrorPage from "../views/error";

import { AuthContextProvider } from "../context/AuthContext";
import Protected from "../auth/Protected";
import UserLogin from "../auth/UserLogin";
import LoadingSection from "../components/LoadingSection";
import { InvoiceContextProvider } from "../context/InvoiceContext";
import { VendorContextProvider } from "../context/VendorContext";
import Header from "../components/Header";
import Coupons from "../views/coupon/Coupons";
import Terms from "../views/terms/Terms";
import AddTerms from "../views/terms/AddTerms";
import Customers from "../views/Customers";

const Home = lazy(() => import("../views/Home"));
const AddCoupon = lazy(() => import("../views/coupon/AddCoupon"));

function Router(props) {
  return (
    <>
      <AuthContextProvider>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="/home"
            element={
              <Protected>
                <Suspense fallback={<LoadingSection status={true} />}>
                  <VendorContextProvider>
                    <Header>
                      <Home />
                    </Header>
                  </VendorContextProvider>
                </Suspense>
              </Protected>
            }
          />
          <Route
            path="/coupons"
            element={
              <Protected>
                <Suspense fallback={<LoadingSection status={true} />}>
                  <VendorContextProvider>
                    <Header>
                      <Coupons />
                    </Header>
                  </VendorContextProvider>
                </Suspense>
              </Protected>
            }
          />
          <Route
            path="/new-coupon"
            element={
              <Protected>
                <Suspense fallback={<LoadingSection status={true} />}>
                  <VendorContextProvider>
                    <Header>
                      <AddCoupon />
                    </Header>
                  </VendorContextProvider>
                </Suspense>
              </Protected>
            }
          />
          <Route
            path="/terms"
            element={
              <Protected>
                <Suspense fallback={<LoadingSection status={true} />}>
                  <VendorContextProvider>
                    <Header>
                      <Terms />
                    </Header>
                  </VendorContextProvider>
                </Suspense>
              </Protected>
            }
          />
          <Route
            path="/new-term"
            element={
              <Protected>
                <Suspense fallback={<LoadingSection status={true} />}>
                  <VendorContextProvider>
                    <Header>
                      <AddTerms />
                    </Header>
                  </VendorContextProvider>
                </Suspense>
              </Protected>
            }
          />

          <Route
            path="/customers"
            element={
              <Protected>
                <Suspense fallback={<LoadingSection status={true} />}>
                  <VendorContextProvider>
                    <Header>
                      <Customers />
                    </Header>
                  </VendorContextProvider>
                </Suspense>
              </Protected>
            }
          />
          {/* <Route
            path="/summary"
            element={
              <Protected>
                <Suspense fallback={<LoadingSection status={true} />}>
                  <VendorContextProvider>
                    <Summary />
                  </VendorContextProvider>
                </Suspense>
              </Protected>
            }
          />
          <Route
            path="/profile"
            element={
              <Protected>
                <Suspense fallback={<LoadingSection status={true} />}>
                  <InvoiceContextProvider>
                    <VendorContextProvider>
                      <Profile />
                    </VendorContextProvider>
                  </InvoiceContextProvider>
                </Suspense>
              </Protected>
            }
          />
          <Route
            path="/subscription"
            element={
              <Protected>
                <Suspense fallback={<LoadingSection status={true} />}>
                  <InvoiceContextProvider>
                    <VendorContextProvider>
                      <Subscription />
                    </VendorContextProvider>
                  </InvoiceContextProvider>
                </Suspense>
              </Protected>
            }
          />
          <Route
            path="/project/:idProj"
            element={
              <Protected>
                <Suspense fallback={<LoadingSection status={true} />}>
                  <InvoiceContextProvider>
                    <ProjectDetails />
                  </InvoiceContextProvider>
                </Suspense>
              </Protected>
            }
          />
          <Route
            path="/project/:idProj/edit"
            element={
              <Protected>
                <Suspense fallback={<LoadingSection status={true} />}>
                  <VendorContextProvider>
                    <EditProject />
                  </VendorContextProvider>
                </Suspense>
              </Protected>
            }
          />
          <Route
            path="/calendar"
            element={
              <Protected>
                <Suspense fallback={<LoadingSection status={true} />}>
                  <VendorContextProvider>
                    <Calendar />
                  </VendorContextProvider>
                </Suspense>
              </Protected>
            }
          />

          <Route
            path="/team"
            element={
              <Protected>
                <Suspense fallback={<LoadingSection status={true} />}>
                  <Team />
                </Suspense>
              </Protected>
            }
          />
          <Route
            path="/settings"
            element={
              <Protected>
                <Suspense fallback={<LoadingSection status={true} />}>
                  <Settings />
                </Suspense>
              </Protected>
            }
          />
          <Route
            path="/about"
            element={
              <Protected>
                <Suspense fallback={<LoadingSection status={true} />}>
                  <About />
                </Suspense>
              </Protected>
            }
          />
          <Route
            path="/tutorial"
            element={
              <Protected>
                <Suspense fallback={<LoadingSection status={true} />}>
                  <Tutorial />
                </Suspense>
              </Protected>
            }
          />
          <Route
            path="/support"
            element={
              <Protected>
                <Suspense fallback={<LoadingSection status={true} />}>
                  <Support />
                </Suspense>
              </Protected>
            }
          />

          <Route
            path="/signup"
            element={
              <UserLogin>
                <Register />
              </UserLogin>
            }
          /> */}
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </AuthContextProvider>
    </>
  );
}

export default Router;
