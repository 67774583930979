import React, { useState, useEffect } from "react";

import { Box, Breadcrumbs, Typography } from "@mui/material/";
import { NavigateNext } from "@mui/icons-material";
import CouponLists from "../../components/table/CouponLists";
import TermLists from "../../components/table/TermLists";

const Terms = () => {
  return (
    <Box>
      <Box display={"flex"} flexDirection={"column"} gap={1} sx={{ p: 4 }}>
        <Typography variant="h5" fontWeight={500}>
          Term & Conditions
        </Typography>
        <Breadcrumbs
          separator={<NavigateNext fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Typography key={1}>Weddlist</Typography>
          <Typography key={2}>Terms</Typography>
          <Typography key={3} color={"primary"}>
            Term & Conditions
          </Typography>
        </Breadcrumbs>
      </Box>
      <TermLists />
    </Box>
  );
};

export default Terms;
